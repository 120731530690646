import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { OFFICIAL_LINE } from 'src/constants';
import FbChatPlugin from 'src/components/FbChatPlugin';
import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import LineIcon from 'src/assets/line.svg';
import HorseIcon from 'src/assets/horse_new.svg';

import styles from './styles.module.css';

@observer
class ChatButton extends React.Component {
  constructor() {
    super();
  }

  render() {
    const hasLine = this.props.appContext.state.plan?.hasLine;
    const hasMessenger = this.props.appContext.state.plan?.hasMessenger;

    return (
      <div className={styles.modal}>
        {(hasLine || hasMessenger) && (
          <div
            className={clsx(styles.outerContainer, !hasLine && styles.noLine)}
          >
            <div className={styles.horseContainer}>
              <img src={HorseIcon} alt="horse" width="100%" height="100%" />
            </div>
          </div>
        )}

        {hasLine && (
          <a
            href={OFFICIAL_LINE}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              window.qg?.('event', 'cs_btn_click', {});
            }}
          >
            <div className={styles.lineBtnContainer}>
              <img src={LineIcon} alt="LineIcon" />
            </div>
          </a>
        )}

        {hasMessenger && <FbChatPlugin />}
      </div>
    );
  }
}

ChatButton.propTypes = {
  appContext: PropTypes.object
};

ChatButton.defaultProps = {
  appContext: null
};

export default withAppStateContext(ChatButton);
